import React from 'react'
import './Home.css'
import Header from '../../components/header/Header'
import Player from '../../components/player/Player'
import About from '../../components/about/About'
import Programming from '../../components/programming/Programming'
import Contact from '../../components/contact/Contact'
import Footer from '../../components/footer/Footer'
import Versiculo from '../../components/versiculo/Versiculo'
import WhatsAppButton from '../../components/whatsapp/Whatsapp'
import { Helmet } from 'react-helmet-async'


const Home = () => {
  return (
    <div className='background-home'>
        <Helmet>
          <title>Ttu Radio JP</title>
          <meta name="description" content="Escucha la mejor música y programas cristianos en español en nuestra emisora latina en Nagoya. Ofrecemos eventos y noticias para la comunidad cristiana en Japón." />
          <link rel="canonical" href="https://tturadiojpadn.com"></link>
        </Helmet>
        {/* <Card /> */}
        <Header />
        <Player />
        <About />
        <Versiculo />
        <Programming />
        <Contact />
        <Footer />
        <WhatsAppButton />
    </div>
  )
}

export default Home