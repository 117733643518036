import React from 'react'
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter} from '@fortawesome/free-brands-svg-icons';



const Footer = () => {
  return (
    <section className='footer'>
      <div className='footer-section container'>
        <div className='footer-media'>
          <a href="https://www.facebook.com/Tturadionazaadnjp" rel="noreferrer" target='_blank'><FontAwesomeIcon className="social facebook" icon={faFacebookF} /></a>
          {/* <a href="http://www.instagram.com" rel="noreferrer" target='_blank'><FontAwesomeIcon className="social instagram" icon={faInstagram} /></a> */}
          <a href="http://www.twitter.com/TturadioJpADN" rel="noreferrer" target='_blank'><FontAwesomeIcon className="social twitter" icon={faTwitter} /></a>
        </div>
      </div>

    </section>


  )
}

export default Footer