import React from 'react'
import './Programming.css'
import Card from './Card'
import data from '../../data/data.json'



const Programming = () => {
  return (
    <section className='programming' id='programming'>
      <div className='programming-title'>
        <h2>Programación</h2>
      </div>
      <article className='programming-content container'>
      {
        data.map(item => 
          <Card 
             key={item.id} titulo={item.titulo} img={item.url_image} descripcion={item.descripcion} hora={item.hora}
          />
        )
      }
      </article>
    </section>
  )
}

export default Programming;
