import React from 'react'
import './Player.css'


const Player = () => {

  return (
    <section className='player' id='player'>
     <div className='player-background container'>
        <div className='player-content'>
          <h1>RADIO <b>EN VIVO</b></h1>
          <audio className="playerMain" controls="controls"  >
            <source src="https://emisorasvirtuales.com.co:9045/live" type="audio/mpeg" />
            Tu navegador audio automatico.
          </audio>
        </div>
      </div>
      {/* <div className='player-versiculo'>
        <p >"No solo de pan vivirá el hombre,<br/> sino de toda palabra que sale de la boca de Dios "<br/>Mateo 4:4 </p> 
      </div> */}

    </section>
     
    
  )
}

export default Player