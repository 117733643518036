import React from "react";
import "./Whatsapp.css";
import whatsappIcon from "../../assets/whatsapp-2.png";
function WhatsAppButton() {
  const handleButtonClick = () => {
    // Acciones a realizar cuando se hace clic en el botón de WhatsApp
    console.log("Botón de WhatsApp presionado");
  };

  return (
    <div className="whatsapp-button">
      <a
        href="https://wa.me/+818072307844"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsappIcon} alt="WhatsApp" onClick={handleButtonClick} />
      </a>
    </div>
  );
}

export default WhatsAppButton;
