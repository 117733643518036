import React from 'react'
import './Versiculo.css'

const Versiculo = () => {
    return (
        <div className='versiculo-content'>
            <div className='versiculo-info'>
                <p >"No solo de pan vivirá el hombre,<br /> sino de toda palabra que sale de la boca de Dios"<br />Mateo 4:4 </p>    
            </div>
        </div>
    )
}

export default Versiculo