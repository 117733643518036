import React from 'react'
import './Contact.css'
import MapView from './map/Map'

const Contact = () => {
  return (
    <section className='contact' id='contact'>
      <div className='contact-map-content'>
        <div className='info-map-content container'>
          <div className='map-container'>
          <MapView />
          </div>
          <div className='info-container'>
            <p>Gokizo Akebono cho 3-18-1 Nagoya-shi, Aichi, Japan 468-0066</p>


          </div>
        </div>
      </div>
      <div className='contact-info-content'>
      <div className='contact-info container'>
       <div className='contact-tittle'><h2>Contacto</h2></div>
        <div className='info'>
        <p><b>Email: </b>pastorlatino1@gmail.com</p>
        </div>
      </div>
      </div>
    </section>
  )
}

export default Contact